import {useState, useEffect} from 'react';
import logo from './logo.svg';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';

interface Battler {
  name: string;
  wins: number;
  losses: number;
  elo: number;
  level: number;
  str: number;
  def: number;
  spd: number;
  exp: number;
  next: number;
}

function App() {

  const [totalMatches, setTotalMatches] = useState<number>();
  const [battlers, setBattlers] = useState<Battler[]>();
  const [loading, setLoading] = useState<boolean>(true)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const results = await axios.get("https://api.matthewgruman.com/grucha/getBattlers");
        const data = results.data;
        let wins = 0;
        for (let i = 0; i < data.length; i++) {
          wins += data[i].wins;
        }
        setTotalMatches(wins);
        const sorted = sortByPropertyDescending(data, 'elo');
        setBattlers(sorted);
        setLoading(false);
        console.log(sorted)
      } catch (error) {
        console.log(error);
      }
    };

    // Fetch data initially
    fetchData();

    // Fetch data every 1000 milliseconds
    const intervalId = setInterval(fetchData, 1000);

    // Clean up the interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  function sortByPropertyDescending(array: any, property: any) {
    return array.slice().sort((a: any, b: any) => {
      if (a[property] > b[property]) {
        return -1;
      }
      if (a[property] < b[property]) {
        return 1;
      }
      return 0;
    });
  }
  return (
    <div className="container">
      <h1>Gruchadors</h1>
      <p>Grucha Libre has been around for 100s of years. Some say it predates Lucha Libre, and you shouldn't talk to those types.</p>
      <p>200 gruchadors do battle against each other endless. The backend is built on Node and Express, and provides the API for all Grucha Libre competition. The front end is React with MUI.</p>
      <p>One match is played every second. Gruchas are matched at random. If your score gets too low you are eliminated.</p>
      <p>Gruchadors is full stack and cross platform. I'm <a href="https://matthewgruman.com">Matthew Gruman</a>.</p>
      <CssBaseline />
      <TableContainer component={Paper}>
      <Table>

      <TableHead>
          <TableRow>
        <TableCell>#</TableCell>
        <TableCell>Name</TableCell>
        <TableCell>Str</TableCell>
        <TableCell>Def</TableCell>
        <TableCell>Spd</TableCell>
        <TableCell>Wins</TableCell>
        <TableCell>Losses</TableCell>
        <TableCell>Exp</TableCell>
        <TableCell>Level</TableCell>
        <TableCell>Elo</TableCell>
      </TableRow>
      </TableHead>
     <TableBody>
      {
        battlers ? battlers.map((item, index) => {
          return (
            <TableRow key={index}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.str}</TableCell>
              <TableCell>{item.def}</TableCell>
              <TableCell>{item.spd}</TableCell>
              <TableCell>{item.wins}</TableCell>
              <TableCell>{item.losses}</TableCell>
              <TableCell>{item.exp}</TableCell>
              <TableCell>{item.level}</TableCell>
               <TableCell>{item.elo}</TableCell>
            </TableRow>
          )
        })
        :
        ""
      }
    </TableBody>
  </Table>
  </TableContainer>
    </div>
  );
}

export default App;
